import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./home.scss";
import BgImage from "../../Assets/images/ui-images/home-banner-new.png";
import CardImg1 from "../../Assets/images/homeScreenTiles/New-Entries.svg";
import CardImg2 from "../../Assets/images/homeScreenTiles/New-Message.svg";
import CardImg3 from "../../Assets/images/homeScreenTiles/Report.svg";
import CardImg4 from "../../Assets/images/homeScreenTiles/Online-Video.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  customerNameAction,
  disableSystemInfeedFieldsAction,
  dolTableDataAction,
  draftActiveStepAction,
  endCustomerAction,
  faultLevelDropdownAction,
  frequencyDropdownAction,
  matTableDataAction,
  matTableDataToDisplayAction,
  outputButtonContentAction,
  projectNameAction,
  rdolTableDataAction,
  salesOfficeInputAction,
  showAdminInfoAction,
  starDeltaTableDataAction,
  voltageDropdownAction,
  storeLandingMenuItemsAction,
} from "../../Redux/Actions/actions";
import { getHomeScreenItems } from "../../APIs/api_Login";
import Loading from "../Components/Loading/Loading";

export default function HomePage() {
  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", newurl);
  localStorage.removeItem("LSSystemInfeedId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const UID = useSelector((state) => state.saveUserIdReducer);
  dispatch(
    draftActiveStepAction({
      isDraftEnquiry: false,
      draftActiveStep: 0,
    })
  );
  dispatch(outputButtonContentAction("Save Project"));

  const icons = {
    "CardImg1": CardImg1,
    "CardImg2": CardImg2,
    "CardImg3": CardImg3,
    "CardImg4": CardImg4,
  };

  const clearLocalStorage = () => {
    dispatch(dolTableDataAction(""));
    dispatch(salesOfficeInputAction(""));
    dispatch(projectNameAction(""));
    dispatch(customerNameAction(""));
    dispatch(endCustomerAction(""));
    dispatch(voltageDropdownAction(""));
    dispatch(frequencyDropdownAction(""));
    dispatch(faultLevelDropdownAction(""));
    dispatch(matTableDataAction(""));
    dispatch(matTableDataToDisplayAction(""));
    dispatch(dolTableDataAction(""));
    dispatch(rdolTableDataAction(""));
    dispatch(starDeltaTableDataAction(""));
    dispatch(showAdminInfoAction(false));
    dispatch(disableSystemInfeedFieldsAction(false));
  };

  const [isLoader, setIsLoader] = useState(false);
  function getHomeScreenMenus() {
    setIsLoader(true);
    getHomeScreenItems(dispatch, siteToken, userDetails, UID, azureToken.token)
      .then((response) => {
        if (response) {
          dispatch(storeLandingMenuItemsAction(response));
          setIsLoader(false);
        } else {
          dispatch(storeLandingMenuItemsAction([]));
          setIsLoader(false);
        }
      })
      .catch((error) => {
        //setDialogOpen(true);
        setIsLoader(false);
      });
  }

  useEffect(() => {
    clearLocalStorage();
    if (userDetails?.id > 0 && siteToken !== "" && landingMenuItems?.length <= 0) {
      setIsLoader(true);
      getHomeScreenMenus();
    }
  }, [userDetails]);

  return (
    <>
      <div className="bg-landing-page">
        <Container>
          <Grid className="homepage-container">
            <Grid item xs={12} className="z-9 bannerImageCont">
              <Grid xs={12} sm={6} md={6} className="bannerTextLeftCont">
                <div className="bannerTextLeft">
                  <div
                    className={
                      userDetails.roleId === 1
                        ? "bannerInnerText adminClick"
                        : "bannerInnerText"
                    }
                    onClick={
                      userDetails.roleId === 1 ? (
                        () => navigate("/AdminUser")
                      ) : (
                        () => { }
                      )
                    }
                  >
                    AMPS v4.0
                    <div className="bannerInnerTextFull">
                      Assured Motor Protection Software
                    </div>
                  </div>
                </div>
                <div className="tagLineText">Simple, Flexible, Reliable</div>
              </Grid>
            </Grid>

            <Grid container className="z-9 cardContainer">
              {landingMenuItems.length > 0 && landingMenuItems?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  className="menuItemGrid"
                >
                  <div className="menu-item-container menu-item-container-bg menuItemCont">
                    <a href={item.menuName == 'Support' ? null : item.menuLink} key={item.menuId}>
                      <div className="menu-item-image-block menu-item-image-block-border">
                        <img
                          src={icons[item.menuImage]}
                          alt={item.menuName}
                          className="menu-item-image cardImage"
                        />
                      </div>
                      {/* height 256px */}
                      <div className="menu-item-text-block cardText">
                        {/* add background for this  */}
                        <span>{item.menuName}</span>
                      </div>
                    </a>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>

      {isLoader === true ? <Loading /> : <></>}

    </>
  );
}
