import React, { useEffect } from "react";
import "./InquirySystem.scss";
import { InquirySystemInput } from "./InquirySystemInput";
import {
  Button,
  makeStyles,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SystemInfeed } from "./SystemInfeed";
import { Configuration } from "./Configuration";
import { Output } from "./Output";
import { useState } from "react";
import axios from "axios";
import SnackbarCustom from "../Components/Snackbar/Snackbar";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  disableSystemInfeedFieldsAction,
  outputButtonContentAction,
} from "../../Redux/Actions/actions";
import Loading from "../Components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["System Infeed", "Configuration", "Output"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <SystemInfeed />;
    case 1:
      return <Configuration />;
    case 2:
      return <Output />;
    default:
      return "Unknown stepIndex";
  }
}

export default function InquirySystem() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeSt = useSelector(
    (state) => state.draftActiveStepReducer.draftActiveStep
  );
  const [activeStep, setActiveStep] = useState(activeSt);
  const steps = getSteps();
  const api = axios.create({
    baseURL: "SystemInfeed",
  });
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const UID = useSelector((state) => state.saveUserIdReducer);
  const outputBtnContent = useSelector(
    (state) => state.outputButtonContentReducer
  );
  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const salesOfficeDetails = useSelector(
    (state) => state.salesOfficeInputReducer
  );
  const salesEngineerDetails = useSelector(
    (state) => state.salesEngineerReducer
  );
  const projectNameDetails = useSelector((state) => state.projectNameReducer);
  const customerNameDetails = useSelector((state) => state.customerNameReducer);
  const endCustomerDetails = useSelector((state) => state.endCustomerReducer);
  const voltageDetails = useSelector((state) => state.voltageDropdownReducer);
  const frequencyDetails = useSelector(
    (state) => state.frequencyDropdownReducer
  );
  const faultLevelDetails = useSelector(
    (state) => state.faultLevelDropdownReducer
  );
  const matTableDetails = useSelector((state) => state.matTableDataReducer);

  const handleNext = () => {
    // mandatory fields check
    //if (
    //  salesOfficeDetails.salesOffice === "" ||
    //  salesOfficeDetails.salesOffice == undefined
    //) {
    //  handleSnackOpen("Sales Office is Empty", "error");
    //  return;
    //}
    if (
      salesEngineerDetails.salesEnginner === "" ||
      salesEngineerDetails === undefined
    ) {
      handleSnackOpen("Sales Engineer is Empty", "error");
      return;
    }
    if (
      projectNameDetails.projectName === "" ||
      projectNameDetails.projectName === undefined
    ) {
      handleSnackOpen("Project Name is Empty", "error");
      return;
    }
    if (
      customerNameDetails.customerName === "" ||
      customerNameDetails.customerName === undefined
    ) {
      handleSnackOpen("Customer Name is Empty", "error");
      return;
    }
    if (
      endCustomerDetails.endCustomer === "" ||
      endCustomerDetails.endCustomer === undefined
    ) {
      handleSnackOpen("End Customer is Empty", "error");
      return;
    }
    if (voltageDetails.voltage === "" || voltageDetails.voltage === undefined) {
      handleSnackOpen("Select voltage", "error");
      return;
    }
    if (
      frequencyDetails.frequency === "" ||
      frequencyDetails.frequency === undefined
    ) {
      handleSnackOpen("Select Frequency", "error");
      return;
    }
    if (
      faultLevelDetails.faultLevel === "" ||
      faultLevelDetails.faultLevel === undefined
    ) {
      handleSnackOpen("Select Fault Level", "error");
      return;
    }

    dispatch(disableSystemInfeedFieldsAction(true));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleSave = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleSubmit = () => {
    if (
      matTableDetails.matTableData == "" ||
      matTableDetails.matTableData == "undefined"
    )
      handleSnackOpen("No Enquiry Found", "error");
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSaveReport = () => {
    if (outputBtnContent === "Save Project") handleSaveProject();
    if (outputBtnContent === "Reports") handleReport();
  };
  const handleSaveProject = async () => {
    setLoading(true);
    const systemInfeedId = localStorage.getItem("LSSystemInfeedId");
    if (systemInfeedId != null) {
      let mstSystemInfeedDetail = {};
      mstSystemInfeedDetail = {
        SystemInfeedId: parseInt(systemInfeedId),
        UserId: userDetails.id,
      };
      const response = await api.put(
        "/UpdateSystemInfeedStatus",
        mstSystemInfeedDetail, {
        headers: {
          Authorization: "Bearer " + siteToken,
          UserEmail: userDetails.emailId,
          userId: UID.id,
          RoleName: userDetails.role?.roleName,
          Gid: userDetails.gid,
          Roleid: userDetails.roleId,
          AzureToken: azureToken.token
        }
      }
      );
      console.log("SystemInfeedData", response.data);
    }
    localStorage.removeItem("LSSystemInfeedId");
    dispatch(outputButtonContentAction("Reports"));
    setLoading(false);
    // navigate('/Reports');
  };
  const handleReport = () => {
    localStorage.removeItem("LSSystemInfeedId");
    navigate("/Reports");
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <div className="inquiry-system-block">
        <InquirySystemInput />
      </div>

      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className="stepperLabel">
                {getStepContent(activeStep)}
              </Typography>

              <Grid container spacing={2} className="jc-center d-flex mt-1">
                <Grid item xs={10} sm={6} md={2}>
                  <Button
                    fullWidth
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className="pt-button--secondary mt-1"
                  >
                    Back
                  </Button>
                </Grid>
                {activeStep === 2 ? (
                  <Grid item xs={10} sm={6} md={2}>
                    <Button
                      fullWidth
                      onClick={handleSaveReport}
                      className="pt-button--primary mt-1"
                    >
                      {outputBtnContent}
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}

                {/* <Grid item xs={10} sm={6} md={2}> */}
                {
                  activeStep === 0 ? (
                    <Grid item xs={10} sm={6} md={2}>
                      <Button
                        fullWidth
                        onClick={handleNext}
                        className="pt-button--primary mt-1"
                      >
                        Next
                      </Button>
                    </Grid>
                  ) : activeStep == 1 ? (
                    <Grid item xs={10} sm={6} md={2}>
                      <Button
                        fullWidth
                        onClick={handleSubmit}
                        className="pt-button--primary mt-1"
                      >
                        Submit
                      </Button>
                    </Grid>
                  ) : (
                    <></>
                  )
                  // <Button
                  //     fullWidth
                  //     onClick={handleReport}
                  //     className="pt-button--primary mt-1"
                  // >
                  //     Reports
                  // </Button>
                }
                {/* </Grid> */}
              </Grid>
            </div>
          )}
              </div>
              {
                  activeStep == 1 ?
                      <div style={{ "marginTop" : "10px"}}>
                  <Grid item xs={12}>
                      <span>
                       Note: The product combination selected from AMPS tool will be either Tested or Recommended. Kindly ensure the appropriate selection according to customer specifications.
                      </span>
                  </Grid>
              </div> :  <></> }
      </div>

      <SnackbarCustom
        open={open}
        message={snackMessage}
        alertType={alertType}
        handleClose={handleClose}
      />

      {loading ? <Loading /> : <></>}
    </>
  );
}