export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};

// AMPS
export const salesOfficeInputAction = (salesOfficeInput) => {
  return {
    type: "salesOfficeInput",
    payLoad: salesOfficeInput,
  };
};

export const salesEngineerAction = (salesEngineer) => {
  return {
    type: "salesEngineer",
    payLoad: salesEngineer,
  };
};
export const projectNameAction = (projectName) => {
  return {
    type: "projectName",
    payLoad: projectName,
  };
};
export const customerNameAction = (customerName) => {
  return {
    type: "customerName",
    payLoad: customerName,
  };
};
export const endCustomerAction = (endCustomer) => {
  return {
    type: "endCustomer",
    payLoad: endCustomer,
  };
};
export const voltageDropdownAction = (voltageDropdown) => {
  return {
    type: "voltageDropdown",
    payLoad: voltageDropdown,
  };
};
export const frequencyDropdownAction = (frequencyDropdown) => {
  return {
    type: "frequencyDropdown",
    payLoad: frequencyDropdown,
  };
};
export const faultLevelDropdownAction = (faultLevelDropdown) => {
  return {
    type: "faultLevelDropdown",
    payLoad: faultLevelDropdown,
  };
};
export const infeedFormDetailsAction = (infeedFormDetails) => {
  return {
    type: "infeedFormDetails",
    payLoad: infeedFormDetails
  }
}

export const matTableDataToDisplayAction = (matTableDataDisplay) => {
  return {
    type: "matTableDataDisplay",
    payLoad: matTableDataDisplay
  }
}
export const matTableDataAction = (matTableData) => {
  return {
    type: "matTableData",
    payLoad: matTableData
  }
}
export const dolTableDataAction = (dolTableData) => {
  return {
    type: "dolTableData",
    payLoad: dolTableData
  }
}

export const rdolTableDataAction = (rdolTableData) => {
  return {
    type: "rdolTableData",
    payLoad: rdolTableData
  }
}
export const starDeltaTableDataAction = (starDeltaTableData) => {
  return {
    type: "starDeltaTableData",
    payLoad: starDeltaTableData
  }
}
// handle these
export const motorRatingAction = (motorRatingDetails) => {
  return {
    type: "motorRatingDetails",
    payLoad: motorRatingDetails
  }
}
export const motorEfficiencyAction = (motorEfficiencyDetails) => {
  return {
    type: "motorEfficiencyDetails",
    payLoad: motorEfficiencyDetails
  }
}
export const motorFLCAction = (motorFLCDetails) => {
  return {
    type: "motorFLCDetails",
    payLoad: motorFLCDetails
  }
}
export const efficiencyClassAction = (efficiencyClassDetails) => {
  return {
    type: "efficiencyClassDetails",
    payLoad: efficiencyClassDetails
  }
}
export const startingMethodAction = (startingMethodDetails) => {
  return {
    type: "startingMethodDetails",
    payLoad: startingMethodDetails
  }
}
export const coordinationAction = (coordinationDetails) => {
  return {
    type: "coordinationDetails",
    payLoad: coordinationDetails
  }
}
export const fuseTypeAction = (fuseTypeDetails) => {
  return {
    type: "fuseTypeDetails",
    payLoad: fuseTypeDetails
  }
}
export const scpdFuseTypeAction = (scpdFuseTypeDetails) => {
  return {
    type: "scpdFuseTypeDetails",
    payLoad: scpdFuseTypeDetails
  }
}
export const contactorAction = (contactorDetails) => {
  return {
    type: "contactorDetails",
    payLoad: contactorDetails
  }
}
export const overloadRelayAction = (overloadRelayDetails) => {
  return {
    type: "overloadRelayDetails",
    payLoad: overloadRelayDetails
  }
}
export const motorLRRAction = (motorLRRDetails) => {
  return {
    type: "motorLRRDetails",
    payLoad: motorLRRDetails
  }
}
export const powerFactorAction = (powerFactorDetails) => {
  return {
    type: "powerFactorDetails",
    payLoad: powerFactorDetails
  }
}
export const motorStartingTimeAction = (motorStartingTimeDetails) => {
  return {
    type: "motorStartingTimeDetails",
    payLoad: motorStartingTimeDetails
  }
}
export const showAdminInfoAction = (showAdminInfo) => {
  return {
    type: 'canShow',
    payLoad: showAdminInfo
  }
}
export const efficiencyTypeInputOptions = (efficiencyTypeInputOptions) => {
  return {
    type: 'efficiencyTypeInput',
    payLoad: efficiencyTypeInputOptions
  }
}
export const motorRatingInputOptions = (motorRatingInputOptions) => {
  return {
    type: 'motorRatingInput',
    payLoad: motorRatingInputOptions
  }
}
export const disableSystemInfeedFieldsAction = (disableSystemInfeedFields) => {
  return {
    type: 'disableSystemInfeedFields',
    payLoad: disableSystemInfeedFields
  }
}
export const draftActiveStepAction = (draftActiveStep) => {
  return {
    type: 'draftActiveStep',
    payLoad: draftActiveStep
  }
}
// export const draftEnqTableDataAction = (draftEnqTableData) => {
//   return {
//     type: 'draftEnqTableData',
//     payLoad: draftEnqTableData
//   }
// }

export const outputButtonContentAction = (outputButtonContent) => {
  return {
    type: 'outputButtonContent',
    payLoad: outputButtonContent
  }
}

export const saveAzureTokenAction = (token) => {
  return {
    type: "saveAzureToken",
    payLoad: token,
  };
};
export const saveUserIdAction = (id) => {
  return {
    type: "encryptedId",
    payLoad: id,
  };
};
export const storeLandingMenuItemsAction = (menuItems) => {
  return {
    type: "saveLandingMenuItems",
    payLoad: menuItems,
  };
};