import React, { useEffect, useState } from 'react';
import {
    Grid, IconButton, Tooltip, TextField
} from "@material-ui/core";
import { useSelector } from "react-redux"; import axios from 'axios';
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import './Reports.scss';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import { Rowing } from '@material-ui/icons';
//import jsPDF from 'jspdf';
//import html2canvas from 'html2canvas'; 
import { jsPDF } from "jspdf";
export default function SummaryReport() {
    //const dispatch = useDispatch();
    const [ReportData, setReportData] = useState([]);
    const [SummaryData, setSummaryData] = useState([]);
    const api = axios.create({
        baseURL: 'ExistingConfiguration'
    })
    useEffect(() => {
        getDraftDetails();
    }, []);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);

    const getDraftDetails = () => {
        let paramDetails = {};
        paramDetails = {
            "UserId": userDetails.id,
            "RoleId": userDetails.roleId,
            //id of motorRating ddl selected value
        }
        api.post('/getReportDetails', paramDetails, {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            },
        })
            .then(res => {
                setReportData(res.data);
                console.log('Data', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }

    const downloadIconButton = (rowData) => {
        return <IconButton className='custIconButton'
            aria-label="InfoOutlined" >
            <Tooltip title="Download Excel" placement="start-top">
                <div>
                    <DescriptionOutlinedIcon
                        className='downloadIcon'
                        onClick={() => downloadExcelIconClick(rowData)} />
                </div>
            </Tooltip>
            <Tooltip title="Download PDF" placement="start-top">
                <div>
                    <PictureAsPdfOutlinedIcon
                        className='downloadIcon'
                        onClick={() => downloadPDFIconClick(rowData)} />
                </div>
            </Tooltip>

        </IconButton >
    }
    async function downloadExcelIconClick(rowData) {
        let paramDetails = {};

        paramDetails = {
            "systemInfeedId": rowData.systemInfeedId,
        }
        const config = {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            },
            responseType: 'arraybuffer'
        };
        await api.post('/getSummaryReportDetailsNew/' + userDetails.firstName + "/" + userDetails.lastName, paramDetails, config)
            .then(res => {
                var blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
                FileSaver.saveAs(blob, "SummaryReport.xlsx");
                console.log("Download Excel Clicked", rowData);
            });
    }

    //function downloadExcelIconClick(rowData) {
    //    let paramDetails = {};

    //    paramDetails = {
    //        "systemInfeedId": rowData.systemInfeedId,

    //    }
    //    api.post('/getSummaryReportDetailsNew', paramDetails,{ responseType: 'arraybuffer' })
    //        .then(res => {
    //            var blob = new Blob([res.data], {
    //                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

    //            });
    //                //.catch(error => {
    //                //    console.log("Error in hitting api at page load.", error)
    //                //});
    //            FileSaver.saveAs(blob, "SupplierReport.xlsx");

    //        });


    //    //var csvdata = {
    //    //    "Project Name": result.projectname,
    //    //    "Customer Name": result.customerName,
    //    //    "Date Created": result.createdDate,
    //    //    "Voltage": result.voltage,
    //    //    "Frequency": result.frequency,
    //    //    "Fault Level": result.faultLevel,
    //    //    "Efficiency": result.efficiencyclass,
    //    //    "KW": result.motorRating,
    //    //    "SCPD Mlfb": result.mlfbMasterData,
    //    //    "SCPD Rating": result.mlfbMasterRatingData,
    //    //    "Contactor Mlfb": result.siriusChartContactorData,
    //    //    "Contactor Rating": result.siriusChartContactorRatingData,
    //    //    "Overload Relay Mlfb": result.olrMlfbData,
    //    //    "Overload Relay Rating": result.olrMlfbRatingData,
    //    //    "Type of Selection": result.enquirytype,
    //    //}
    //    //console.log('CSV Data', csvdata);
    //    //const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    //    //const fileExtension = '.xlsx';

    //    //var fileName = "summaryreport";

    //    ////const exportToCSV = (rowData, fileName) => {

    //    //const ws = XLSX.utils.json_to_sheet([rowData]);
    //    //const  wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    //    //const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    //    //const data = new Blob([excelBuffer], { type: fileType });

    //    //FileSaver.saveAs(data, fileName + fileExtension);
    //    //}

    //    console.log("Download Excel Clicked", rowData);
    //}
    //function downloadExcelIconClick(rowData) {
    //    let paramDetails = {};
    //    paramDetails = {
    //        "systemInfeedId": rowData.systemInfeedId
    //    }
    //    api.post('/getSummaryReportDetails', paramDetails)
    //        .then(res => {
    //            setSummaryData(res.data);
    //            const worksheet = XLSX.utils.json_to_sheet(res.data);
    //            const workbook = XLSX.utils.book_new();
    //            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //            //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //            //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    //            XLSX.writeFile(workbook, "SummaryReport.xlsx");
    //            console.log('SummaryData', res.data);
    //        })
    //        .catch(error => {
    //            console.log("Error in hitting api at page load.", error)
    //        });
    //    //var csvdata = {
    //    //    "Project Name": result.projectname,
    //    //    "Customer Name": result.customerName,
    //    //    "Date Created": result.createdDate,
    //    //    "Voltage": result.voltage,
    //    //    "Frequency": result.frequency,
    //    //    "Fault Level": result.faultLevel,
    //    //    "Efficiency": result.efficiencyclass,
    //    //    "KW": result.motorRating,
    //    //    "SCPD Mlfb": result.mlfbMasterData,
    //    //    "SCPD Rating": result.mlfbMasterRatingData,
    //    //    "Contactor Mlfb": result.siriusChartContactorData,
    //    //    "Contactor Rating": result.siriusChartContactorRatingData,
    //    //    "Overload Relay Mlfb": result.olrMlfbData,
    //    //    "Overload Relay Rating": result.olrMlfbRatingData,
    //    //    "Type of Selection": result.enquirytype,
    //    //}
    //    //console.log('CSV Data', csvdata);
    //    //const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    //    //const fileExtension = '.xlsx';

    //    //var fileName = "summaryreport";

    //    ////const exportToCSV = (rowData, fileName) => {

    //    //const ws = XLSX.utils.json_to_sheet([rowData]);
    //    //const  wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    //    //const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    //    //const data = new Blob([excelBuffer], { type: fileType });

    //    //FileSaver.saveAs(data, fileName + fileExtension);
    //    //}

    //    console.log("Download Excel Clicked", rowData);
    //}
    function downloadPDFIconClick(rowData) {
        let paramDetails = {};
        paramDetails = {
            "systemInfeedId": rowData.systemInfeedId,
        }
        const config = {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            },
            responseType: 'arraybuffer'
        };
        api.post('/getSummaryReportDetailsPDF', paramDetails, config)
            .then(res => {
                var blob = new Blob([res.data], {
                    type: "application/pdf",
                })
                FileSaver.saveAs(blob, "SummaryReport.pdf");
                console.log("Download Excel Clicked", rowData);
            });



        // //const downloadPdfDocument = () => {
        //     const input = rowData;
        //     html2canvas(input)
        //         .then((canvas) => {
        //             const imgData = canvas.toDataURL('image/png');
        //             const pdf = new jsPDF();
        //             pdf.addImage(imgData, 'JPEG', 0, 0);
        //             pdf.save(`${downloadFileName}.pdf`);
        //         })
        //// }
        console.log("Download PDF Clicked", rowData);
    }

    var columns = [
        { title: "SystemInfeedId", field: "SystemInfeedId", hidden: true },
        {
            title: 'S.No',
            field: 'tableData', hidden: true

        },
        {
            title: 'Project Name',
            field: 'projectName',
        },
        {
            title: 'Customer Name',
            field: 'customerName',
        },
        {
            title: 'Date Created',
            field: 'modifiedDate',
            type: "date",
            dateSetting: { locale: "en-GB" },
        },

        {
            title: 'Download',
            field: 'download',
            width: "10%",
            render: (rowData) => downloadIconButton(rowData)
        },


        // {
        //     title: 'Voltage',
        //     field: 'voltage',
        // },
        // {
        //     title: 'Frequency',
        //     field: 'frequency',
        // },
        // { title: 'Fault Level', field: 'faultLevel' },
        // { title: 'Starting Method', field: 'method' },
        // { title: 'Eff', field: 'efficiencyclass' },
        // { title: 'KW', field: 'motorRating' },
        // { title: 'SCPD MLFB', field: 'mlfbMasterData' },
        // { title: 'SCPD Rating', field: 'mlfbMasterRatingData' },
        // { title: 'Contactor MLFB', field: 'siriusChartContactorData' },
        // { title: 'Contactor Rating', field: 'siriusChartContactorRatingData' },
        // { title: 'Overload Realy MLFB', field: 'olrMlfbData' },
        // { title: 'Overload Realy Rating', field: 'olrMlfbRatingData' },
        // { title: 'Type of selection', field: 'enquirytype' },

    ];

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
        textAlign: "center",
        fontWeight: 'bold'
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: '600px',
        sorting: true,
        // exportButton: true,
        cellStyle: {
            textAlign: "center"
        }
    };

    return (
        <div className="App">
            <Grid container spacing={1}>

                <Grid item xs={12} className="d-flex jc-space-bt">

                    <Grid item xs={12} className="text-left ml-1">

                        <MaterialTable
                            //title={getTableTitle()}
                            columns={columns}
                            data={ReportData}
                            icons={tableIcons}
                            // isLoading={isLoader}
                            options={tableOptions}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}