import React, { useEffect, useState } from 'react';
import {
    Grid, IconButton, Tooltip, TextField, Button

} from "@material-ui/core";
import { useSelector } from "react-redux"; import axios from 'axios';
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import './Reports.scss';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//import * as FileSaver from 'file-saver';
import { Rowing } from '@material-ui/icons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default function DetailedReport() {
    //const dispatch = useDispatch();
    const [ReportData, setReportData] = useState([]);
    const [SummaryData, setSummaryData] = useState([]);
    const [ProjectList, setProjectList] = useState([]);
    const [UserData, setUserData] = useState([]);
    const [projectname, setprojectname] = useState('');
    const [salesoffice, setsalesoffice] = useState('');
    const [salesEnggValue, setsalesEngg] = useState('');
    const [customername, setCustomername] = useState('');
    const [endcustomer, setEndcustomer] = useState('');
    const [fromdate, setFromdate] = useState('');
    const [todate, setTodate] = useState('');
    const api = axios.create({
        baseURL: 'ExistingConfiguration'
    });
    const apiuser = axios.create({
        baseURL: 'UserManagement'
    })
    useEffect(() => {
        getReportDetails();
        getUsers();

        //getProjectList();
    }, []);
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);

    const getUsers = () => {
        apiuser.get('/getAllUsers', {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            },
        })
            .then(res => {
                setUserData(res.data);
                console.log('Data', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const getProjectList = () => {
        api.post('/getProjectList')
            .then(res => {
                console.log("projectname", res.data);
                setProjectList(res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const getReportDetails = () => {
        let paramDetails = {};
        paramDetails = {
            "UserId": userDetails.id,
            "RoleId": userDetails.roleId,
            //id of motorRating ddl selected value
        }
        api.post('/getReportDetails', paramDetails, {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            },
        })
            .then(res => {
                setReportData(res.data);
                console.log('Data', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    }

    const downloadIconButton = (rowData) => {
        return <IconButton className='custIconButton'
            aria-label="InfoOutlined" >
            {/*<Tooltip title="Download Excel" placement="start-top">*/}
            {/*    <div>*/}
            {/*        <DescriptionOutlinedIcon*/}
            {/*            className='downloadIcon'*/}
            {/*            onClick={() => downloadExcelIconClick(rowData)} />*/}
            {/*    </div>*/}
            {/*</Tooltip>*/}
            <Tooltip title="Download PDF" placement="start-top">
                <div>
                    <PictureAsPdfOutlinedIcon
                        className='downloadIcon'
                        onClick={() => downloadPDFIconClick(rowData)} />
                </div>
            </Tooltip>

        </IconButton >
    }

    function downloadExcelIconClick(rowData) {
        let paramDetails = {};
        paramDetails = {
            "systemInfeedId": rowData.systemInfeedId,
        }
        api.post('/getDetailedReportDetails', paramDetails,
            {
                headers: {
                    Authorization: "Bearer " + siteToken,
                    UserEmail: userDetails.emailId,
                    userId: UID.id,
                    RoleName: userDetails.role?.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    AzureToken: azureToken.token
                }
            }
        )
            .then(res => {
                setSummaryData(res.data);
                const worksheet = XLSX.utils.json_to_sheet(res.data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
                //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
                XLSX.writeFile(workbook, "DetailedReport.xlsx");
                console.log('DetailedData', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            });
        //var csvdata = {
        //    "Project Name": result.projectname,
        //    "Customer Name": result.customerName,
        //    "Date Created": result.createdDate,
        //    "Voltage": result.voltage,
        //    "Frequency": result.frequency,
        //    "Fault Level": result.faultLevel,
        //    "Efficiency": result.efficiencyclass,
        //    "KW": result.motorRating,
        //    "SCPD Mlfb": result.mlfbMasterData,
        //    "SCPD Rating": result.mlfbMasterRatingData,
        //    "Contactor Mlfb": result.siriusChartContactorData,
        //    "Contactor Rating": result.siriusChartContactorRatingData,
        //    "Overload Relay Mlfb": result.olrMlfbData,
        //    "Overload Relay Rating": result.olrMlfbRatingData,
        //    "Type of Selection": result.enquirytype,
        //}
        //console.log('CSV Data', csvdata);
        //const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        //const fileExtension = '.xlsx';

        //var fileName = "summaryreport";

        ////const exportToCSV = (rowData, fileName) => {

        //const ws = XLSX.utils.json_to_sheet([rowData]);
        //const  wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        //const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        //const data = new Blob([excelBuffer], { type: fileType });

        //FileSaver.saveAs(data, fileName + fileExtension);
        //}

        console.log("Download Excel Clicked", rowData);
    }
    async function downloadPDFIconClick(rowData) {
        let paramDetails = {};
        paramDetails = {
            "systemInfeedId": rowData.systemInfeedId,
        }
        const config = {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            },
            responseType: 'arraybuffer'
        };
        api.post('/getDetailedReportDetailsPDF/', paramDetails, config)
            .then(res => {
                var blob = new Blob([res.data], {
                    type: "application/pdf",
                })
                FileSaver.saveAs(blob, "DetailedReport.pdf");
                console.log("Download Excel Clicked", rowData);
            });

        //paramDetails = {
        //    "systemInfeedId": rowData.systemInfeedId,

        //}
        //await api.post('/getDetailedReportDetailsExcel', paramDetails, { responseType: 'arraybuffer' })
        //    .then(res => {
        //        var blob = new Blob([res.data], {
        //            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        //        })
        //        FileSaver.saveAs(blob, "DetailedReport.xlsx");
        //        console.log("Download Excel Clicked", rowData);
        //    });
        //var downloadFileName = "Summaryreportpdf";
        // //const downloadPdfDocument = () => {
        //     const input = rowData;
        //     html2canvas(input)
        //         .then((canvas) => {
        //             const imgData = canvas.toDataURL('image/png');
        //             const pdf = new jsPDF();
        //             pdf.addImage(imgData, 'JPEG', 0, 0);
        //             pdf.save(`${downloadFileName}.pdf`);
        //         })
        //// }
        //console.log("Download PDF Clicked", rowData);
    }

    var columns = [
        { title: "SystemInfeedId", field: "SystemInfeedId", hidden: true },
        {
            title: 'S.No',
            field: 'tableData', hidden: true

        },
        {
            title: 'Project Name',
            field: 'projectName',
        },
        {
            title: 'Customer Name',
            field: 'customerName',
        },
        {
            title: 'Date Created',
            field: 'modifiedDate',
            type: "date",
            dateSetting: { locale: "en-GB" },
        },

        {
            title: 'Download',
            field: 'download',
            width: "10%",
            render: (rowData) => downloadIconButton(rowData)
        },


        // {
        //     title: 'Voltage',
        //     field: 'voltage',
        // },
        // {
        //     title: 'Frequency',
        //     field: 'frequency',
        // },
        // { title: 'Fault Level', field: 'faultLevel' },
        // { title: 'Starting Method', field: 'method' },
        // { title: 'Eff', field: 'efficiencyclass' },
        // { title: 'KW', field: 'motorRating' },
        // { title: 'SCPD MLFB', field: 'mlfbMasterData' },
        // { title: 'SCPD Rating', field: 'mlfbMasterRatingData' },
        // { title: 'Contactor MLFB', field: 'siriusChartContactorData' },
        // { title: 'Contactor Rating', field: 'siriusChartContactorRatingData' },
        // { title: 'Overload Realy MLFB', field: 'olrMlfbData' },
        // { title: 'Overload Realy Rating', field: 'olrMlfbRatingData' },
        // { title: 'Type of selection', field: 'enquirytype' },

    ];

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
        textAlign: "center",
        fontWeight: 'bold'
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: '600px',
        sorting: true,
        // exportButton: true,
        cellStyle: {
            textAlign: "center"
        }
    };
    function handleSubmit() {
        var Salesuserid = 0;
        if (userDetails.roleId == 1) {
            if (salesEnggValue != "" && salesEnggValue != null) {
                Salesuserid = salesEnggValue;
            }
        }
        else {
            Salesuserid = userDetails.id;
        }
        let paramDetails = {
            UserId: Salesuserid,
            RoleId: userDetails.roleId,
            ProjectName: projectname,//need to pick project name
            CustomerName: customername,
            EndCustomer: endcustomer,
            SaleOffice: salesoffice,
            Fromdate: fromdate,//need to pick date in datepicker
            Todate: todate,//need to pick date in datepicker
            //SalesEngineer:salesEngg
            //id of motorRating ddl selected value

        }
        api.post('/getDetailedRptProjectDetails', paramDetails, {
            headers: {
                Authorization: "Bearer " + siteToken,
                UserEmail: userDetails.emailId,
                userId: UID.id,
                RoleName: userDetails.role?.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                AzureToken: azureToken.token
            }
        })
            .then(res => {
                setReportData(res.data);
                console.log('Data', res.data);
            })
            .catch(error => {
                console.log("Error in hitting api at page load.", error)
            })
    };


    function handleprojectFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setprojectname(field);
    }
    function handlesalesofficeFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setsalesoffice(value);
    }
    function handlesalesEngineerFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setsalesEngg(value);
    }
    function handlecustomerFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setCustomername(value);
    }
    function handleendcustomerFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setEndcustomer(value);

    }
    function handleFromdateFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setFromdate(value);
    }
    function handleTodateFieldChange(e) {
        const value = e.target.value;
        const field = e.target.name;
        setTodate(value);
    }

    return (

        <div className="App">


            <Grid container spacing={1}>
                {/*sales office and sales engineer field is only visible for admin user*/}
                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="salesoffice"*/}
                {/*    type="text"*/}
                {/*    label="Sales Office"*/}
                {/*    variant="filled"*/}
                {/*    value={salesoffice}*/}
                {/*    onChange={handlesalesofficeFieldChange}*/}
                {/*    id="salesoffice"*/}
                {/*/>*/}
                {/*<FormControl variant="filled">*/}
                {/*    <InputLabel id="efficiency-label">Sales Engineer </InputLabel>*/}
                {/*    <Select*/}
                {/*        name="salesengg"*/}
                {/*        label="demo-simple-select-helper-label"*/}
                {/*        id="salesengg"*/}
                {/*        value={salesEnggValue}*/}
                {/*        onChange={handlesalesEngineerFieldChange}*/}
                {/*    >*/}

                {/*        <MenuItem value="" disabled>*/}
                {/*            Sales Engineer*/}
                {/*        </MenuItem>*/}
                {/*        {UserData.map((item, index) => (*/}
                {/*            <MenuItem key={index} value={item.id}>*/}
                {/*                {item.firstName + " " + item.lastName}*/}
                {/*            </MenuItem>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}

                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="salesengg"*/}
                {/*    type="text"*/}
                {/*    label="Sales Engineer"*/}
                {/*    variant="filled"*/}
                {/*    value={salesEngg}*/}
                {/*    onChange={handlesalesEngineerFieldChange}*/}
                {/*    id="salesengg"*/}
                {/*/>*/}
                {/*<FormControl  variant="filled">*/}
                {/*    <InputLabel id="efficiency-label">Project Name </InputLabel>*/}
                {/*    <Select*/}
                {/*        name="projectname"*/}
                {/*        label="demo-simple-select-helper-label"*/}
                {/*        id="projectname"*/}
                {/*        value={projectname}*/}
                {/*    onChange={handleprojectFieldChange}*/}
                {/*    >*/}

                {/*        <MenuItem value="" disabled>*/}
                {/*            Project Name*/}
                {/*        </MenuItem>*/}
                {/*        {ReportData.map((item, index) => (*/}
                {/*            <MenuItem key={index} value={item.systemInfeedId}>*/}
                {/*                {item.projectName}*/}
                {/*            </MenuItem>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}

                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="projectName"*/}
                {/*    type="text"*/}
                {/*    label="Project Name"*/}
                {/*    variant="filled"*/}
                {/*    value={projectname}*/}
                {/*    onChange={handleprojectFieldChange}*/}
                {/*    id="projectName"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="customername"*/}
                {/*    type="text"*/}
                {/*    label="Customer Name"*/}
                {/*    variant="filled"*/}
                {/*    value={customername}*/}
                {/*    onChange={handlecustomerFieldChange}*/}
                {/*    id="customername"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="endcustomer"*/}
                {/*    type="text"*/}
                {/*    label="End Customer"*/}
                {/*    variant="filled"*/}
                {/*    value={endcustomer}*/}
                {/*    onChange={handleendcustomerFieldChange}*/}
                {/*    id="endcustomer"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="fromdate"*/}
                {/*    type="text"*/}
                {/*    label="From Date"*/}
                {/*    variant="filled"*/}
                {/*    value={fromdate}*/}
                {/*    onChange={handleFromdateFieldChange}*/}
                {/*    id="fromdate"*/}
                {/*/>*/}
                {/*<TextField*/}
                {/*    className="mt-1"*/}
                {/*    name="todate"*/}
                {/*    type="text"*/}
                {/*    label="To Date"*/}
                {/*    variant="filled"*/}
                {/*    value={todate}*/}
                {/*    onChange={handleTodateFieldChange}*/}
                {/*    id="todate"*/}
                {/*/>*/}

                {/*<Button*/}
                {/*    onClick={handleSubmit}*/}
                {/*    className="pt-button--primary mt-1"*/}
                {/*>*/}
                {/*    Submit*/}
                {/*</Button>*/}
                <Grid item xs={12} className="d-flex jc-space-bt">
                    <Grid item xs={12} className="text-left ml-1">
                        <MaterialTable
                            //title={getTableTitle()}
                            columns={columns}
                            data={ReportData}
                            icons={tableIcons}
                            // isLoading={isLoader}
                            options={tableOptions}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}