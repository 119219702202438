import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import InfoOutlined from "@material-ui/icons/VisibilityOutlined";
import { InfoiconDraftDialog } from "./InfoiconDraftDialog";
import { useNavigate } from "react-router-dom";
import InquirySystem from "../InquirySystem/InquirySystem";
import {
  disableSystemInfeedFieldsAction,
  dolTableDataAction,
  draftActiveStepAction,
  draftEnqTableDataAction,
  matTableDataAction,
  rdolTableDataAction,
  starDeltaTableDataAction,
} from "../../Redux/Actions/actions";
import {
  salesEngineerAction,
  salesOfficeInputAction,
  projectNameAction,
  customerNameAction,
  endCustomerAction,
  voltageDropdownAction,
  frequencyDropdownAction,
  faultLevelDropdownAction,
} from "../../Redux/Actions/actions";
import Loading from "../Components/Loading/Loading";

export default function ExistingConfiguration() {
  //const dispatch = useDispatch();
  const [draftData, setDraftData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const api = axios.create({
    baseURL: "ExistingConfiguration",
  });
  const [loading, setLoading] = useState(false);
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const UID = useSelector((state) => state.saveUserIdReducer);
  useEffect(() => {
    getDraftDetails();
    setLoading(true);
    dispatch(
      draftActiveStepAction({
        isDraftEnquiry: true,
        draftActiveStep: 1,
      })
    );
  }, []);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const getDraftDetails = () => {
    let paramDetails = {};
    paramDetails = {
      UserId: userDetails.id, //id of motorRating ddl selected value
    };
    api
      .post("/getSystemInfeedDraftDetails", paramDetails,
        {
          headers: {
            Authorization: "Bearer " + siteToken,
            UserEmail: userDetails.emailId,
            userId: UID.id,
            RoleName: userDetails.role?.roleName,
            Gid: userDetails.gid,
            Roleid: userDetails.roleId,
            AzureToken: azureToken.token
          },
        }
      )
      .then((res) => {
        setDraftData(res.data);
        console.log("Data", res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error in hitting api at page load.", error);
        setLoading(false);
      });
  };
  const segregateOutputData = (apiData) => {
    let configurationRequests = [];

    if (apiData !== "" && apiData !== null && apiData !== undefined)
      configurationRequests = apiData.configurationRequests;
    let dolData = [];
    let RDOLData = [];
    let sdData = [];

    configurationRequests.forEach((element) => {
      if (element.startingMethod == 1) dolData.push(element);
      if (element.startingMethod == 2) RDOLData.push(element);
      if (element.startingMethod == 3) sdData.push(element);
    });

    dispatch(dolTableDataAction(dolData));
    dispatch(rdolTableDataAction(RDOLData));
    dispatch(starDeltaTableDataAction(sdData));
  };
  const infoIconButton = (rowData) => {
    return (
      <IconButton
        aria-label="InfoOutlined"
        onClick={() => infoIconClick(rowData)}
      >
        <Tooltip title="View enquiries" placement="start-top">
          <InfoOutlined />
        </Tooltip>
      </IconButton>
    );
  };
  const [selectedRowData, setSelectedRowData] = useState("");
  async function infoIconClick(rowData) {
    dispatch(
      draftActiveStepAction({
        isDraftEnquiry: true,
        draftActiveStep: 1,
      })
    );

    dispatch(
      salesOfficeInputAction({
        date: rowData.createdDate,
        salesOffice: rowData.saleOffice,
      })
    );
    dispatch(projectNameAction(rowData.projectName));
    dispatch(customerNameAction(rowData.customerName));
    dispatch(endCustomerAction(rowData.endCustomer));
    dispatch(voltageDropdownAction(rowData.voltage));
    dispatch(frequencyDropdownAction(rowData.frequency));
    dispatch(faultLevelDropdownAction(rowData.faultLevel));
    dispatch(disableSystemInfeedFieldsAction(true));
    //set systeminfeed id to local storage
    localStorage.setItem(
      "LSSystemInfeedId",
      JSON.stringify(rowData.systemInfeedId)
    );
    let apiData = {};
    let paramDetails = {};
    paramDetails = {
      UserId: userDetails.id,
      SystemInfeedId: rowData.systemInfeedId, //id of motorRating ddl selected value
    };
    // api.post('/getSystemInfeedLineItem', paramDetails)
    //     .then(res => {
    //         console.log('Data', res.data);
    //         apiData = res.data;
    //     })
    //     .catch(error => {
    //         console.log("Error in hitting api at page load.", error)
    //     })

    const response = await api.post("/getSystemInfeedLineItem", paramDetails, {
      headers: {
        Authorization: "Bearer " + siteToken,
        UserEmail: userDetails.emailId,
        userId: UID.id,
        RoleName: userDetails.role?.roleName,
        Gid: userDetails.gid,
        Roleid: userDetails.roleId,
        AzureToken: azureToken.token
      },
    });
    apiData = response.data;
    if (apiData !== "" || apiData !== undefined)
      dispatch(matTableDataAction(apiData.configurationRequests));

    segregateOutputData(apiData);

    setSelectedRowData(rowData);
    // setInfoDialogOpen(true);
    navigate("/InquirySystem", { state: { active_St: apiData } });
    // window.history.pushState('/InquirySystem');
  }
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const handleInfoDialogClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    //setSelectedRowData('');
    setInfoDialogOpen(false);
  };
  var columns = [
    { title: "SystemInfeedId", field: "systemInfeedId", hidden: true },

    {
      title: "Project Name",
      field: "projectName",
    },
    {
      title: "Customer Name",
      field: "customerName",
    },
    {
      title: "End Customer",
      field: "endCustomer",
    },
    {
      title: "Sales Office",
      field: "saleOffice",
    },
    {
      title: "Date",
      field: "createdDate",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    {
      title: "View",
      width: "10%",
      render: (rowData) => infoIconButton(rowData),
    },
  ];

  const tableHeaderStyle = {
    backgroundColor: "var(--light-sand)",
    color: "var(--dark-blue)",
  };
  const tableOptions = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
  };

  return (
    <>
      <div className="App">
        <Grid container spacing={1}>
          <Grid item xs={12} className="d-flex jc-space-bt">
            <Grid item xs={12} className="text-left ml-1">
              <h2>Existing Configuration</h2>
              <MaterialTable
                //title={getTableTitle()}
                columns={columns}
                data={draftData}
                icons={tableIcons}
                // isLoading={isLoader}
                options={tableOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className="infeedFormDialog">
          <Dialog
            fullWidth
            maxWidth="md"
            open={infoDialogOpen}
            onClose={handleInfoDialogClose}
            aria-labelledby="responsive-dialog-title-info"
            disableEscapeKeyDown
          >
            <DialogContent>
              <InfoiconDraftDialog selectedRowData={selectedRowData} />
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2} className="jc-center d-flex mt-1">
                <Grid item xs={10} sm={4} md={3}>
                  <Button
                    fullWidth
                    onClick={handleInfoDialogClose}
                    className="pt-button--secondary mt-1"
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      {loading ? <Loading /> : <></>}
    </>
  );
}
