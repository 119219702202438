import { combineReducers } from "redux";

let userInitialState = {
  gid: "",
};
let tokenInitialState = {
  token: "",
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    id: 0,
  },
};
let errorMessageInitialState = "";

const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

let azureToken = {
  token: "",
};
const saveAzureTokenReducer = (state = azureToken, action) => {
  switch (action.type) {
    case "saveAzureToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const errorMessageReducer = (
  state = errorMessageInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "errorMessage": {
      return payLoad;
    }
    default: {
      return state;
    }
  }
};


// AMPS
let salesOfficeInputInitialState = {
  date: "",
  salesOffice: ""
};
const salesOfficeInputReducer = (state = salesOfficeInputInitialState, action) => {
  switch (action.type) {
    case "salesOfficeInput": {
      return {
        date: action.payLoad.date,
        salesOffice: action.payLoad.salesOffice
      };
    }
    default: {
      return state;
    }
  }
};

let salesEngineerInitialState = '';
const salesEngineerReducer = (state = salesEngineerInitialState, action) => {
  switch (action.type) {
    case "salesEngineer": {
      return {
        salesEngineer: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let projectNameInitialState = '';
const projectNameReducer = (state = projectNameInitialState, action) => {
  switch (action.type) {
    case "projectName": {
      return {
        projectName: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let customerNameInitialState = '';
const customerNameReducer = (state = customerNameInitialState, action) => {
  switch (action.type) {
    case "customerName": {
      return {
        customerName: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let endCustomerInitialState = '';
const endCustomerReducer = (state = endCustomerInitialState, action) => {
  switch (action.type) {
    case "endCustomer": {
      return {
        endCustomer: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}

let voltageDropdownInitialState = {};
const voltageDropdownReducer = (state = voltageDropdownInitialState, action) => {
  switch (action.type) {
    case "voltageDropdown": {
      return {
        voltage: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let frequencyDropdownInitialState = {};
const frequencyDropdownReducer = (state = frequencyDropdownInitialState, action) => {
  switch (action.type) {
    case "frequencyDropdown": {
      return {
        frequency: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let faultLevelDropdownInitialState = {};
const faultLevelDropdownReducer = (state = faultLevelDropdownInitialState, action) => {
  switch (action.type) {
    case "faultLevelDropdown": {
      return {
        faultLevel: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}

let matTableDataDisplayInitialState = {};
const matTableDataDisplayReducer = (state = matTableDataDisplayInitialState, action) => {
  switch (action.type) {
    case "matTableDataDisplay": {
      return {
        matTableDataDisplay: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let matTableDataInitialState = {};
const matTableDataReducer = (state = matTableDataInitialState, action) => {
  switch (action.type) {
    case "matTableData": {
      return {
        matTableData: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let dolTableDataInitialState = [];
const dolTableDataReducer = (state = dolTableDataInitialState, action) => {
  switch (action.type) {
    case "dolTableData": {
      return {
        dolTableData: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let rdolTableDataInitialState = [];
const rdolTableDataReducer = (state = rdolTableDataInitialState, action) => {
  switch (action.type) {
    case "rdolTableData": {
      return {
        rdolTableData: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let starDeltaTableDataInitialState = [];
const starDeltaTableDataReducer = (state = starDeltaTableDataInitialState, action) => {
  switch (action.type) {
    case "starDeltaTableData": {
      return {
        starDeltaTableData: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}


// hadnlethese
let motorRatingInitialState = {};
const motorRatingReducer = (state = motorRatingInitialState, action) => {
  switch (action.type) {
    case "motorRatingDetails": {
      return {
        motorRating: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let motorEfficiencyInitialState = {};
const motorEfficiencyReducer = (state = motorEfficiencyInitialState, action) => {
  switch (action.type) {
    case "motorEfficiencyDetails": {
      return {
        motorEfficiency: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let motorFLCInitialState = {};
const motorFLCReducer = (state = motorFLCInitialState, action) => {
  switch (action.type) {
    case "motorFLCDetails": {
      return {
        motorFLC: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let efficiencyClassInitialState = {};
const efficiencyClassReducer = (state = efficiencyClassInitialState, action) => {
  switch (action.type) {
    case "efficiencyClassDetails": {
      return {
        efficiencyClass: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let startingMethodInitialState = {};
const startingMethodReducer = (state = startingMethodInitialState, action) => {
  switch (action.type) {
    case "startingMethodDetails": {
      return {
        startingMethod: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let coordinationInitialState = {};
const coordinationReducer = (state = coordinationInitialState, action) => {
  switch (action.type) {
    case "coordinationDetails": {
      return {
        coordination: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let fuseTypeInitialState = {};
const fuseTypeReducer = (state = fuseTypeInitialState, action) => {
  switch (action.type) {
    case "fuseTypeDetails": {
      return {
        fuseType: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let scpdFuseTypeInitialState = {};
const scpdFuseTypeReducer = (state = scpdFuseTypeInitialState, action) => {
  switch (action.type) {
    case "scpdFuseTypeDetails": {
      return {
        scpdFuseType: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let contactorInitialState = {};
const contactorReducer = (state = contactorInitialState, action) => {
  switch (action.type) {
    case "contactorDetails": {
      return {
        contactor: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let overloadRelayInitialState = {};
const overloadRelayReducer = (state = overloadRelayInitialState, action) => {
  switch (action.type) {
    case "overloadRelayDetails": {
      return {
        overloadRelay: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let motorLRRInitialState = {};
const motorLRRReducer = (state = motorLRRInitialState, action) => {
  switch (action.type) {
    case "motorLRRDetails": {
      return {
        motorLRR: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let powerFactorInitialState = {};
const powerFactorReducer = (state = powerFactorInitialState, action) => {
  switch (action.type) {
    case "powerFactorDetails": {
      return {
        powerFactor: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}
let motorStartingTimeInitialState = {};
const motorStartingTimeReducer = (state = motorStartingTimeInitialState, action) => {
  switch (action.type) {
    case "motorStartingTimeDetails": {
      return {
        motorStartingTime: action.payLoad
      };
    }
    default: {
      return state;
    }
  }
}

let showAdminInfoInitialState = false;
const showAdminInfoReducer = (state = showAdminInfoInitialState, action) => {
  switch (action.type) {
    case "canShow": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
}


let efficiencyTypeInputInitialState = {};
const efficiencyTypeInputReducer = (state = efficiencyTypeInputInitialState, action) => {
  switch (action.type) {
    case "efficiencyTypeInput": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
}
let motorRatingInputInitialState = {};
const motorRatingInputReducer = (state = motorRatingInputInitialState, action) => {
  switch (action.type) {
    case "motorRatingInput": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
}

let disableSystemInfeedFieldsInitialState = false;
export const disableSystemInfeedFieldsReducer = (state = disableSystemInfeedFieldsInitialState, action) => {
  switch (action.type) {
    case 'disableSystemInfeedFields': {
      return action.payLoad
    }
    default: {
      return state;
    }
  }
}

let draftActiveStepInitialState = 0;
export const draftActiveStepReducer = (state = draftActiveStepInitialState, action) => {
  switch (action.type) {
    case 'draftActiveStep': {
      return action.payLoad
    }
    default: {
      return state
    }
  }
}

// let draftEnqTableDataInitialState = {};
// export const draftEnqTableDataReducer = (state = draftEnqTableDataInitialState, action) => {
//   switch (action.type) {
//     case 'draftEnqTableData': {
//       return action.payLoad
//     }
//     default: {
//       return state
//     }
//   }
// }

let outputButtonContentInitialState = '';
export const outputButtonContentReducer = (state = outputButtonContentInitialState, action) => {
  switch (action.type) {
    case 'outputButtonContent': {
      return action.payLoad
    }
    default: { }
      return state
  }
}
let encryptedId = {
  id: "",
};
const saveUserIdReducer = (state = encryptedId, action) => {
  switch (action.type) {
    case "encryptedId": {
      return {
        id: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const storeMenuItems = [];
const storeLandingMenuItemsReducer = (state = storeMenuItems, action) => {
  switch (action.type) {
    case "saveLandingMenuItems": {
      return {
        menuItems: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
}

export const masterReducer = combineReducers({
  authUserReducer: authUserReducer,
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  errorMessageReducer: errorMessageReducer,
  salesOfficeInputReducer: salesOfficeInputReducer,
  salesEngineerReducer: salesEngineerReducer,
  projectNameReducer: projectNameReducer,
  customerNameReducer: customerNameReducer,
  endCustomerReducer: endCustomerReducer,
  voltageDropdownReducer: voltageDropdownReducer,
  frequencyDropdownReducer: frequencyDropdownReducer,
  faultLevelDropdownReducer: faultLevelDropdownReducer,
  matTableDataDisplayReducer: matTableDataDisplayReducer,
  matTableDataReducer: matTableDataReducer,
  dolTableDataReducer: dolTableDataReducer,
  rdolTableDataReducer: rdolTableDataReducer,
  starDeltaTableDataReducer: starDeltaTableDataReducer,

  motorRatingReducer: motorRatingReducer,
  motorEfficiencyReducer: motorEfficiencyReducer,
  motorFLCReducer: motorFLCReducer,
  efficiencyClassReducer: efficiencyClassReducer,
  startingMethodReducer: startingMethodReducer,
  coordinationReducer: coordinationReducer,
  fuseTypeReducer: fuseTypeReducer,
  scpdFuseTypeReducer: scpdFuseTypeReducer,
  contactorReducer: contactorReducer,
  overloadRelayReducer: overloadRelayReducer,
  motorLRRReducer: motorLRRReducer,
  powerFactorReducer: powerFactorReducer,
  motorStartingTimeReducer: motorStartingTimeReducer,
  showAdminInfoReducer: showAdminInfoReducer,
  efficiencyTypeInputReducer: efficiencyTypeInputReducer,
  motorRatingInputReducer: motorRatingInputReducer,
  disableSystemInfeedFieldsReducer: disableSystemInfeedFieldsReducer,
  draftActiveStepReducer: draftActiveStepReducer,
  // draftEnqTableDataReducer: draftEnqTableDataReducer,
  outputButtonContentReducer: outputButtonContentReducer,
  saveAzureTokenReducer: saveAzureTokenReducer,
  saveUserIdReducer: saveUserIdReducer,
  storeLandingMenuItemsReducer: storeLandingMenuItemsReducer
});
