import React from "react";
import { Grid } from "@material-ui/core";

export default function Error() {
    return (
        <div className="zoom-90">
            <Grid item xs={12} className="align-center width-100" style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "30px"
            }}>
                <h2>
                    You don't have permission to access this URL.
                </h2>
            </Grid>
        </div>
    );
}