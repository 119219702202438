import { Routes, Route, Redirect, Navigate } from "react-router-dom";
import Home from '../Pages/Home/home';
import Login from '../Pages/Login/login';
import UserManagement from "../Pages/UserManagement/UserManagement";
import SystemInfeedInputDetails from "../Pages/SystemInfeedInputDetails/SystemInfeedInputDetails";
import InquirySystem from "../Pages/InquirySystem/InquirySystem";
import SystemInfeedForm from "../Pages/InquirySystem/SystemInfeedForm";
import AdminUser from "../Pages/UserManagement/AdminUser";
import ExistingConfiguration from "../Pages/ExistingConfiguration/ExistingConfiguration";
import SummaryReport from "../Pages/Reports/SummaryReport";
import DetailedReport from "../Pages/Reports/DetailedReport";
import Reports from "../Pages/Reports/Reports";
import Error from "./UnAuthorizedRoute";

export const MasterRoute = (
    <Routes>
        <Route path="/signin-oidc*" element={<Navigate to="/" />} />
        <Route path="/signin-oidc#code=*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/NewConfiguration" element={<InquirySystem />} />
        <Route exact path="/InquirySystem" element={<InquirySystem />} />
        <Route exact path="/UserManagement" element={<UserManagement />} />
        <Route exact path="/SystemInfeedInputDetails" element={<SystemInfeedInputDetails />} />
        <Route exact path="/SystemInfeedForm" element={<SystemInfeedForm />} />
        <Route exact path="/SystemInfeedInputDetails" element={<SystemInfeedInputDetails />} />
        <Route exact path="/AdminUser" element={<AdminUser />} />
        <Route exact path="/DraftEnquiries" element={<ExistingConfiguration />} />
        <Route exact path="/ExistingConfiguration" element={<ExistingConfiguration />} />
        <Route exact path="/SummaryReport" element={<SummaryReport />} />
        <Route exact path="/DetailedReport" element={<DetailedReport />} />
        <Route exact path="/Reports" element={<Reports />} />
        <Route path="*" element={< Error />} />
    </Routes >
);

export const ExtUserRoute = (
    <Routes>
        <Route path="/signin-oidc*" element={<Navigate to="/" />} />
        <Route path="/signin-oidc#code=*" element={<Navigate to="/" />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/NewConfiguration" element={<InquirySystem />} />
        <Route exact path="/InquirySystem" element={<InquirySystem />} />
        <Route exact path="/SystemInfeedInputDetails" element={<SystemInfeedInputDetails />} />
        <Route exact path="/SystemInfeedForm" element={<SystemInfeedForm />} />
        <Route exact path="/SystemInfeedInputDetails" element={<SystemInfeedInputDetails />} />
        <Route exact path="/DraftEnquiries" element={<ExistingConfiguration />} />
        <Route exact path="/ExistingConfiguration" element={<ExistingConfiguration />} />
        <Route exact path="/SummaryReport" element={<SummaryReport />} />
        <Route exact path="/DetailedReport" element={<DetailedReport />} />
        <Route exact path="/Reports" element={<Reports />} />
        <Route path=
            "*"
            element
            =
            {
                <
                    Error
                />} />
    </Routes>
);