import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from "material-table";
import { tableIcons } from "../../Utilities/utility";
import './Configuration.scss';

export function DOLOutput() {
    let DOLTableData = [];
    DOLTableData = useSelector((state) =>
        state.dolTableDataReducer
    );
    const motorRating = useSelector((state) =>
        state.motorRatingInputReducer
    );
    const efficiencyType = useSelector((state) =>
        state.efficiencyTypeInputReducer
    );
    // const staticDolData = [
    //     { "rating": "0.18", "motorEfficiency": 0.685, "flc": 0.5, "efficiencyClass": "IE2", "startingMethod": "DOL", "coOrdination": "Type-1", "types": "Fuse", "scpd": "3NA", "contactor": "3TF", "overLoadRelay": "3UA", "coolingFanMotorCurrent": "3", "powerFactor": 0.69, "serviceFactor": 1 }
    // ];
    function renderSelectionType(adminEnquiryType) {
        const selType = adminEnquiryType === 1 ? 'Tested' : 'Recommended';
        return selType;
    }

    function renderCoOrdinationType(CoordinationValue) {
        const selType = CoordinationValue === 1 ? 'Type-1' : 'Type-2';
        return selType;
    }
    function renderMotorRating(value) {
        const motRating = motorRating.find(x => x.id === value)?.motorRating;
        return motRating;
    }
    function renderEfficiencyType(effValue) {
        const effVal = parseInt(effValue);
        const effType = efficiencyType.find(x => x.id === effVal)?.efficiencyType;
        return effType;
    }

    var columns = [
        // { title: "Eff", field: 'efficiencyClass', width: '10%' },
        {
            title: 'Eff. Class',
            field: 'class',
            width: "10%",
            render: (rowData) => renderEfficiencyType(rowData.class)
        },


        // { title: 'Motor Rating(kW)', field: 'rating' },
        {
            title: 'Motor Rating (kW)',
            field: 'motorRating',
            width: "5%",
            render: (rowData) => renderMotorRating(rowData.motorRating)
        },
        { title: 'Motor FLC (A)', field: 'motorFlc' },
        { title: 'SCPD Type', field: 'mlfbMasterData' },
        { title: 'SCPD Rating (A)', field: 'mlfbMasterRatingData' },
        { title: 'Contactor Type', field: 'siriusChartContactorData' },
        { title: 'Contactor Rating (A)', field: 'siriusChartContactorRatingData' },
        { title: 'OLPD Type', field: 'olrMlfbData' },
        { title: 'OLPD Rating (A)', field: 'olrMlfbRatingData' },
        {
            title: 'Co-ordination Type', field: 'typesOfCoordination',
            render: (rowData) => renderCoOrdinationType(rowData.typesOfCoordination)
        }

        // { title: 'Motor Efficiency', field: 'motorEfficiency' },
        // { title: 'FLC (A)', field: 'flc' },
        // { title: 'LLR', field: 'lrr', hidden: true },
        // { title: 'Starting Method', field: 'startingMethod' },
        // { title: 'Coordination', field: 'coOrdination' },
        // { title: 'Types', field: 'types' },
        // { title: 'SCPD', field: 'scpd' },
        // { title: 'Contractor', field: 'contactor' },
        // { title: 'OverloadRelay', field: 'overLoadRelay' },
        // { title: 'Cooling Fan Motor Current', field: 'coolingFanMotorCurrent', hidden: true },
        // { title: 'Power Factor', field: 'powerFactor', hidden: true },
        // { title: 'Service Factor', field: 'serviceFactor', hidden: true }
    ];

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        // exportButton: {
        //     csv: true,
        //     pdf: false
        // },
        // exportAllData: true,
        pageSize: 3,
        pageSizeOptions: [3, 10, 20, 30],
        // emptyRowsWhenPaging: false
        sorting: false
    };

    return (
        <>
            {DOLTableData.dolTableData != '' ? (
                <div className='matTableClass'>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={DOLTableData.dolTableData || ''}
                        icons={tableIcons}
                        options={tableOptions} />
                </div>) : <h4>No DOL Enquiry found</h4>}
        </>
    );
}